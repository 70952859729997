//
import store from '@/store'
import { Service } from 'model-wrapper'
import CatcherService from '@/services/catcher'

//
export default class extends Service {

  /**
   * 
   */
  static store() {
    return store
  }

  /**
   * 
   */
  static translate(payload) {
    return CatcherService.translate(payload, this.langs())
  }

  /**
   * 
   */
  static langs() {
    return new Map()
  }
}