//
import AppLayoutRouter from '@/layouts/router'

//
export default {
  path: '/proveedor',
  component: AppLayoutRouter,
  children: [
    {
      path: ':permalink',
      name: 'p-provider',
      component: () => import(/* webpackChunkName: "g-user-account" */ '@/views/Provider/PProvider/profile'),
    },
    {
      path: '/explorar',
      name: 'p-provider-explore',
      component: () => import(/* webpackChunkName: "p-provider-explore" */ '@/views/Provider/PProvider/explore'),
    },
  ],
}