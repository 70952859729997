//
import AppLayoutRouter from "@/layouts/router";

//
export default {
  path: "/perfil",
  component: AppLayoutRouter,
  children: [
    /**
     * Administrador
     */
    {
      path: "administrador",
      component: () =>
        import(
          /* webpackChunkName: "g-user-account-admin" */ "@/views/General/GUserAccount/admin"
        ),
      children: [
        {
          path: "",
          name: "g-user-account",
          component: () =>
            import(
              /* webpackChunkName: "g-user-account-account" */ "@/views/General/GUserAccount/account"
            ),
        },
        {
          path: "servicios",
          name: "g-user-account-categories",
          component: () =>
            import(
              /* webpackChunkName: "g-user-account-categories" */ "@/views/General/GUserAccount/categories"
            ),
        },
        {
          path: "rubros",
          name: "g-user-account-subcategories",
          component: () =>
            import(
              /* webpackChunkName: "g-user-account-subcategories" */ "@/views/General/GUserAccount/subcategories"
            ),
        },
        {
          path: "mineras",
          name: "g-user-account-companies",
          component: () =>
            import(
              /* webpackChunkName: "g-user-account-companies" */ "@/views/MiningCompany/McMiningCompany/list"
            ),
        },
        {
          path: "usuarios",
          name: "g-user-account-users",
          component: () =>
            import(
              /* webpackChunkName: "g-user-account-users" */ "@/views/General/GUserAccount/list"
            ),
        },
        {
          path: "galeria",
          name: "g-user-account-gallery",
          component: () =>
            import(
              /* webpackChunkName: "g-user-account-gallery" */ "@/views/General/GImageGallery/list"
            ),
        },
        {
          path: "red",
          name: "g-web-page-red",
          component: () =>
            import(
              /* webpackChunkName: "g-web-page-red" */ "@/views/General/GWebPageRed/edit"
            ),
        },
        {
          path: "concursos",
          name: "g-web-page-contest",
          component: () =>
            import(
              /* webpackChunkName: "g-web-page-contest" */ "@/views/General/GWebPage/edit"
            ),
        },
        {
          path: "plataformas",
          name: "g-user-account-platforms",
          component: () =>
            import(
              /* webpackChunkName: "g-user-account-platforms" */ "@/views/General/GUserAccount/platforms"
            ),
        },
        {
          path: "contraseña",
          name: "g-user-account-password",
          component: () =>
            import(
              /* webpackChunkName: "g-user-account-password" */ "@/views/General/GUserAccount/password"
            ),
        },
      ],
    },

    /**
     * Compañía Minera
     */
    {
      path: "minera",
      component: () =>
        import(
          /* webpackChunkName: "mc-mining-company-profile" */ "@/views/MiningCompany/McMiningCompany/profile"
        ),
      children: [
        {
          path: "",
          name: "mc-mining-company",
          component: () =>
            import(
              /* webpackChunkName: "mc-mining-company-account" */ "@/views/MiningCompany/McMiningCompany/account"
            ),
        },
        {
          path: "plataformas",
          name: "mc-mining-company-platforms",
          component: () =>
            import(
              /* webpackChunkName: "mc-mining-company-platforms" */ "@/views/General/GUserAccount/platforms"
            ),
        },
        {
          path: "usuarios",
          name: "mc-mining-company-users",
          component: () =>
            import(
              /* webpackChunkName: "mc-mining-company-users" */ "@/views/MiningCompany/McMiningCompany/users"
            ),
        },
        {
          path: "contraseña",
          name: "g-user-account-password",
          component: () =>
            import(
              /* webpackChunkName: "g-user-account-password" */ "@/views/General/GUserAccount/password"
            ),
        },
      ],
    },

    /**
     * Proveedor
     */
    {
      path: "proveedor",
      component: () =>
        import(
          /* webpackChunkName: "g-user-account-profile" */ "@/views/General/GUserAccount/profile"
        ),
      children: [
        {
          path: "",
          name: "g-user-account-provider",
          component: () =>
            import(
              /* webpackChunkName: "g-user-account-provider" */ "@/views/General/GUserAccount/provider"
            ),
        },
        {
          path: "actividades",
          name: "g-user-account-activities",
          component: () =>
            import(
              /* webpackChunkName: "g-user-account-activities" */ "@/views/General/GUserAccount/activities"
            ),
        },
        {
          path: "servicios",
          name: "g-user-account-areas",
          component: () =>
            import(
              /* webpackChunkName: "g-user-account-areas" */ "@/views/General/GUserAccount/areas"
            ),
        },
        {
          path: "encuesta",
          name: "g-user-account-survey",
          component: () =>
            import(
              /* webpackChunkName: "g-user-account-survey" */ "@/views/General/GUserAccount/survey"
            ),
        },
        {
          path: "multimedia",
          name: "g-user-account-media",
          component: () =>
            import(
              /* webpackChunkName: "g-user-account-media" */ "@/views/General/GUserAccount/media"
            ),
        },
        {
          path: "documentos",
          name: "g-user-account-documents",
          component: () =>
            import(
              /* webpackChunkName: "g-user-account-documents" */ "@/views/General/GUserAccount/documents"
            ),
        },
        {
          path: "proyectos",
          name: "g-user-account-projects",
          component: () =>
            import(
              /* webpackChunkName: "g-user-account-projects" */ "@/views/General/GUserAccount/projects"
            ),
        },
        {
          path: "reportes",
          name: "g-user-account-report",
          component: () =>
            import(
              /* webpackChunkName: "g-user-account-report" */ "@/views/Provider/PProvider/report"
            ),
          props: {
            esFueraFaena: 1,
            esFaena: 1,
            esPerfil: 0,
          },
        },
        {
          path: "representante",
          name: "g-user-account-representative",
          component: () =>
            import(
              /* webpackChunkName: "g-user-account-representative" */ "@/views/Provider/PProvider/representative"
            ),
        },
        {
          path: "plataformas",
          name: "g-user-account-platforms",
          component: () =>
            import(
              /* webpackChunkName: "g-user-account-platforms" */ "@/views/Provider/PProvider/platforms"
            ),
        },
        {
          path: "rrss",
          name: "g-user-account-rrss",
          component: () =>
            import(
              /* webpackChunkName: "g-user-account-rrss" */ "@/views/General/GUserAccount/rrss"
            ),
        },
        {
          path: "contraseña",
          name: "g-user-account-password",
          component: () =>
            import(
              /* webpackChunkName: "g-user-account-password" */ "@/views/General/GUserAccount/password"
            ),
        },
      ],
    },
  ],
};
