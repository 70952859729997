// required modules
import Service from "@/services/api/Service";
import PProviderModel from "@/models/Provider/PProvider";
import PProviderLang from "@/services/lang/Provider/PProvider";
// import jsPDF from 'jspdf'
import PProviderReport from "@/services/reports/Provider/PProviderReport";

/**
 * This class describes a PProvider service.
 *
 * @class PProvider (name)
 */
export default class PProviderService extends Service {
  /**
   *
   */
  static areas(pProvider, areas) {
    return pProvider.many("areas").sync({
      areas: areas.map((e) => ({
        providerAreaId: e.id,
        contactName: e.contactName,
        contactPhone: e.contactPhone,
        contactMail: e.contactMail,
      })),
    });
  }

  /**
   *
   */
  static activities(pProvider) {
    return pProvider.many("economic_activities").sync({
      economicActivities: pProvider.economicActivities.map((e) => e.id),
    });
  }

  /**
   *
   */
  static answerSurvey(pProviderId, answers) {
    console.log("the answers >>", answers);
    return this.shape({ id: pProviderId })
      .many("answer_survey")
      .attach(answers);
    // console.log({ pProviderId, answers })
  }

  static answerSurveyFaena(pProviderId, answers) {
    //console.log("the answers >>",answers)
    return this.shape({ id: pProviderId })
      .many("answer_survey_faena")
      .attach(answers);
    // console.log({ pProviderId, answers })
  }

  /**
   *
   */
  static certifications(pProvider) {
    return pProvider.many("certifications").sync({
      certifications: pProvider.certifications.map((e) => e.id),
    });
  }

  /**
   *
   * @param {*} pProviderId
   * @param {*} pProviderPartner
   */
  static createPartner(pProviderId, pProviderPartner) {
    return this.shape({ id: pProviderId })
      .many("partner")
      .attach(pProviderPartner);
  }

  /**
   *
   * @param {*} pProviderId
   * @param {*} pProviderPartner
   */
  static deletePartner(pProviderId, pProviderPartner) {
    return this.shape({ id: pProviderId })
      .many("partner/" + pProviderPartner.id)
      .delete();
  }

  /**
   *
   */
  static document(pProvider, pProviderDocument) {
    return pProvider.many("document").transfer(pProviderDocument.toDocument());
  }

  /**
   *
   */
  static documentDelete(pProvider, pProviderDocumentId) {
    return pProvider.many(`document/${pProviderDocumentId}`).delete();
  }

  /**
   *
   */
  static documentDownload(pProvider, pProviderDocument) {
    return pProvider
      .many(`document/${pProviderDocument.id}/content`)
      .query()
      .fetch({ responseType: "blob" })
      .then((res) => {
        // console.log({ res })
        // console.log({ pProviderDocument })

        var fileLink = document.createElement("a");
        var fileName = pProviderDocument.filename;
        var fileURL = URL.createObjectURL(res.data);

        // return blob file
        fileLink.href = fileURL;
        fileLink.download = fileName;
        fileLink.click();
      });
  }

  /**
   *
   */
  static documentUpdate(pProvider, pProviderDocument) {
    let aux = this.shape(pProviderDocument).toDocument();
    return pProvider.many(`document/${pProviderDocument.id}`).transfer(aux);
  }

  /**
   *
   */
  static getAllDocumentsById(pProviderId) {
    return this.shape({ id: pProviderId })
      .many("document")
      .query()
      .param("get_all_results", "true")
      .get();
  }

  /**
   *
   */
  static getAllProjectsById(pProviderId) {
    return this.shape({ id: pProviderId })
      .many("project")
      .query()
      .param("get_all_results", "true")
      .get();
  }

  /**
   *
   */
  static getById(pProviderId) {
    return this.model().find(pProviderId);
  }

  /**
   *
   */
  static getByIdWhitRegions(pProviderId) {
    return this.model().include("commune.province.region").find(pProviderId);
  }

  /**
   *
   * @param {*} opts
   */
  static getPartnersByProviderId(pProviderId) {
    return this.shape({ id: pProviderId }).many("partner").query().paginate();
  }

  /**
   *
   */
  static getPublicProviders(opts = {}) {
    return this.shape()
      .many("public")
      .query()
      .where("search", opts.search)
      .where("providerId", opts.providerId)
      .where("categoryId", opts.categoryId)
      .where("communeId", opts.communeId)
      .where("provinceId", opts.provinceId)
      .where("regionId", opts.regionId)
      .where("scoreId", opts.scoreId)
      .where("serviceId", opts.serviceId)
      .where("sizeId", opts.sizeId)
      .where("subcategoryId", opts.subcategoryId)
      .limit(opts.limit)
      .page(opts.page)
      .orderBy(opts.descending ? "score" : "-score")
      .paginate();
  }

  /**
   *
   */
  static getPublicProvidersFaena(opts = {}) {
    return this.shape()
      .many("public")
      .query()
      .where("providerId", opts.providerId)
      .where("categoryId", opts.categoryId)
      .where("communeId", opts.communeId)
      .where("provinceId", opts.provinceId)
      .where("regionId", opts.regionId)
      .where("scoreId", opts.scoreId)
      .where("serviceId", opts.serviceId)
      .where("sizeId", opts.sizeId)
      .where("subcategoryId", opts.subcategoryId)
      .limit(opts.limit)
      .page(opts.page)
      .orderBy(opts.descending ? "scoreFaena" : "-scoreFaena")
      .paginate();
  }

  /**
   *
   */
  static getSurvey(pProviderId) {
    return this.shape({ id: pProviderId }).many("survey").query().get();
  }

  // static getSurvey(pProviderId) {
  //   return this.shape({ id: pProviderId +'/survey' })
  //   //return this.shape({ id: gImageGallery.order +'&only'} ).transfer(gImageGallery.form(['hipervinculo']))
  // }

  /**
   *
   */
  static langs() {
    return PProviderLang.toAll();
  }

  /**
   *
   */
  static legalRepresentative(pProvider) {
    return pProvider
      .many("legal_representative")
      .sync(pProvider.legalRepresentative);
  }

  /**
   *
   */
  static logo(pProvider) {
    return pProvider.logo().transfer(pProvider.toLogo());
  }

  /**
   *
   */
  static match(pProviderId) {
    return this.shape({ id: "match" }).attach({
      providerId: pProviderId,
    });
  }

  /**
   *
   */
  static matchContact(pProviderArea, message) {
    return this.shape({ id: "match" }).many("contact").attach({
      providerAreaId: pProviderArea.id,
      message: message,
    });
  }

  /**
   * Define the PProvider model.
   * @return {Function} PProvider resource.
   */
  static model() {
    return PProviderModel;
  }

  /**
   * Genera un reporte en PDF con los resultados de la evaluación de criterios y
   * requisitos generales.
   * @param {array}   images      - lista de imágenes (gráficos)
   * @param {string}  name        - nombre del proveedor
   * @param {string}  rut         - rut del proveedor
   * @param {string}  category    - categoría del proveedor
   * @param {string}  scoreName    - categoría del proveedor
   * @param {string}  score       - calificación del proveedor
   * @param {boolean} isProvider  - si es proveedor
   * @param {array}   criteria    - criterios del proveedor
   * @param {int} questions - cantidad de preguntas de la encuesta
   */
  static printReport(
    images,
    name,
    rut,
    category,
    scoreName,
    score,
    isProvider,
    criteria = [],
    questions
  ) {
    //
    let pProviderReport = PProviderReport.from({
      images,
      name,
      rut,
      category,
      scoreName,
      score,
      isProvider,
      criteria,
      questions,
    });

    //
    pProviderReport.print();
  }

  /**
   *
   */
  static project(pProvider, pProviderProject) {
    return pProvider.many("project").attach(pProviderProject);
  }

  /**
   *
   */
  static projectDelete(pProvider, pProviderProjectId) {
    return pProvider.many(`project/${pProviderProjectId}`).delete();
  }

  /**
   *
   */
  static projectUpdate(pProvider, pProviderProject) {
    return pProvider
      .many(`project/${pProviderProject.id}`)
      .sync(pProviderProject);
  }

  /**
   *
   */
  static saveSurvey(pProviderId, answers) {
    return this.shape({ id: pProviderId }).many("save_survey").attach(answers);
  }

  static saveSurveyFaena(pProviderId, answers) {
    return this.shape({ id: pProviderId })
      .many("save_survey_faena")
      .attach(answers);
  }

  /**
   *
   */
  static search(opts) {
    return this.shape().many("search").attach({
      rut: opts.rut,
    });
  }

  /**
   *
   */
  static services(pProvider) {
    return pProvider.many("services").sync({
      services: pProvider.services.map((service) => {
        return {
          providerServiceName: String(service.name),
          providerServiceDescription: String(service.description),
        };
      }),
    });
  }

  /**
   *
   */
  static subcategories(pProvider) {
    return pProvider.many("subcategories").sync({
      subcategories: pProvider.subcategories.map((e) => e.id),
    });
  }

  static types(pProvider) {
    return pProvider
      .many("types")
      .attach(pProvider.type);
  }

  static toCreatePartner() {
    return {
      rut: "",
      name: "",
      participationPercentage: 50.0,
    };
  }

  /**
   *
   */
  static toLogo() {
    return this.shape({
      id: "",
      image: null,
    });
  }

  /**
   *
   */
  static toDocument() {
    return this.shape({
      name: "",
      file: null,
      g_provider_document_type_id: 1
    });
  }

  /**
   *
   */
  static toProject() {
    return this.shape({
      title: "Proyecto APEA",
      description: "Proyecto con equipo de éxito académico de la UCN",
      initDate: "2020-04-20",
      endDate: "2020-08-20",
      webUrl: "https://www.apea.cl/proyecto",
      videoUrl: "https://www.youtube.com/hash-video-apea",
    });
  }

  /**
   *
   * @param {*} pProviderId
   */
  static unblockSurvey(pProviderId) {
    return this.shape({ id: pProviderId }).many("unblock_survey").attach();
  }

  static unblockSurveyFaena(pProviderId) {
    return this.shape({ id: pProviderId })
      .many("unblock_survey_faena")
      .attach();
  }

  /**
   *
   * @param {*} pProviderId
   * @param {*} pProviderPartner
   */
  static updatePartner(pProviderId, pProviderPartner) {
    return this.shape({ id: pProviderId })
      .many("partner/" + pProviderPartner.id)
      .sync(pProviderPartner);
  }

  static getSurveyFaena(pProviderId) {
    return this.shape({ id: pProviderId + "/survey" });
    //return this.shape({ id: gImageGallery.order +'&only'} ).transfer(gImageGallery.form(['hipervinculo']))
  }
  /**
   *
   */
  static video(pProvider) {
    return pProvider.many("video").sync({
      videoUrl: pProvider.videoUrl,
    });
  }

  /**
   * Obtiene los proveedores con el filtro "search"
   * @param {*} search
   * @return {Promise}
   */
  static getAllBySearch(search = "") {
    return this.shape()
      .many("public")
      .query()
      .where("search", search)
      .param("get_all_results", true)
      .paginate();
  }
}
