// api store module
export default {
  namespaced: true,
  actions: {

    /**
     * Commit the set provider data
     * 
     * @param {*} context 
     * @param {*} payload s
     */
    setData(context, payload) {
      context.commit('setData', payload.data)
    },

    /**
     * 
     */
    storeData: (context) => {
      localStorage.setItem('eim-provider', JSON.stringify(context.state.data))
    },

    /**
     * Commit the reset auth module
     * 
     * @param {*} context 
     * @param {*} payload 
     */
    reset: (context) => {
      context.commit('setData', {})
      context.dispatch('storeData')
    },
  },
  getters: {

    /**
     * 
     */
    hasData(state) {
      for(var prop in state.data) {
        return true
      }
      return false
    },

    /**
     * 
     */
    notData(state) {
      for(var prop in state.data) {
        return false
      }
      return true
    },
  },
  mutations: {

    /**
     * Set the provider data
     * 
     * @param {*} state 
     * @param {*} token 
     */
    setData(state, data) {
      state.data = data
    },
  },
  state: {
    data: JSON.parse(localStorage.getItem('eim-provider') || '{}'),
  },
}