// required modules
import Service from "@/services/api/Service";
import GEventModel from "@/models/General/GEvent";

/**
 * This class describes a GEvent service.
 *
 * @class GEvent (name)
 */
export default class GEventService extends Service {
  /**
   * Define the GEvent model.
   *
   * @return {Function} GEvent resource.
   */
  static model() {
    return GEventModel;
  }

  /**
   *
   */
  static getById(code = "") {
    return this.query().find(code);
  }

  /**
   *
   */
  static update(gWebPage) {
    return gWebPage.sync(gWebPage);
  }

  static register(form) {
    return this.shape(form).save();
  }
  static getAllWithDetails($id) {
    return this.query().find($id);
  }
}
