//
import Vue from 'vue'
import VueRouter from 'vue-router'

//
import auth from '@/router/guards/auth'

//
import routes from '@/router/routes'

//
Vue.use(VueRouter)

//
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() { 
    return { x: 0, y: 0 }; 
  }
})

//
router.beforeEach(auth)

//
export default router
