// vuex
import Vue from "vue";
import Vuex from "vuex";

// modules
import AuthModule from "@/store/modules/AuthModule";
import ProviderModule from "@/store/modules/ProviderModule";
import UserModule from "@/store/modules/UserModule";
import RegisterModule from "@/store/modules/RegisterModule";

// install
Vue.use(Vuex);

// define
export default new Vuex.Store({
  modules: {
    AuthModule,
    ProviderModule,
    UserModule,
    RegisterModule,
  },
});
