// required modules
import Model from '@/models/Model'

/**
 * This class describes a GImageGallery model.
 *
 * @class GImageGallery
 */
export default class GImageGalleryModel extends Model {
  
  /**
   * 
   */
  get fileUrl() {
    return `${ this.$uri }/content?filePath=${ this.filePath }`
  }

  /**
   * Define the GImageGallery resource.
   *
   * @return {String} GImageGallery endpoint name.
   */
  static resource() {
    return 'image_gallery'
  }
}