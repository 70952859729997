// required modules
import Model from "@/models/Model";

/**
 * This class describes a GEventDetailProvider model.
 *
 * @class GEventDetailProvider
 */
export default class GEventModel extends Model {
  get fileUrl() {
    return (
      window.location.origin + "/web_page/content?filePath=" + this.filePath
    );
  }
  /**
   * Define the GEventDetailProvider resource.
   *
   * @return {String} GEventDetailProvider endpoint name.
   */
  static resource() {
    return "events";
  }

  /**
   *
   */
  get lastModifiedAtShorted() {
    return this.lastModifiedAt.split(":").slice(0, 2).join(":");
  }

  // register(form) {
  //   return this.reduce({form: [{"g_provider_id", "g_event_detail_id", "input_value"]);
  // }
}
