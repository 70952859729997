export default {
  namespaced: true,
  actions: {
    setData(context, payload) {
      context.commit("setData", payload.data);
    },
  },
  getters: {
    /**
     *
     */
    hasData(state) {
      for (var prop in state.data) {
        return true;
      }
      return false;
    },

    /**
     *
     */
    notData(state) {
      for (var prop in state.data) {
        return false;
      }
      return true;
    },
  },
  mutations: {
    /**
     * Set the user service data
     *
     * @param {*} state
     * @param {*} data
     */
    setData(state, data) {
      state.data = data;
    },
  },
  state: {
    data: JSON.parse(localStorage.getItem("eim-register") || "{}"),
  },
};
