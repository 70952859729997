// required modules
import Service from '@/services/api/Service'
import PSubcategoryModel from '@/models/Provider/PSubcategory'

/**
 * This class describes a PSubcategory service.
 *
 * @class PSubcategory (name)
 */
export default class PSubcategoryService extends Service {

  /**
   * 
   */
  static create(pSubcategory) {
    return this.shape(pSubcategory).save()
  }

  /**
   * Define the PSubcategory model.
   *
   * @return {Function} PSubcategory resource.
   */
  static model() {
    return PSubcategoryModel
  }

  /**
   * 
   */
  static getAll() {
    return this
      .query()
      .limit(-1)
      .get()
  }

  /**
   * 
   */
  static getAllPaginate() {
    return this
      .query()
      .limit(-1)
      .paginate()
  }

  /**
   * 
   */
  static getAllPaginateByCategoryId(pCategoryId) {
    return this
      .query()
      .where('categoryId', pCategoryId)
      .limit(-1)
      .paginate()
  }

  /**
   * 
   */
  static getBy(opts = {}) {
    return this
      .query()
      .page(opts.page)
      .limit(opts.limit)
      .paginate()
  }

  /**
   * 
   * @param {*} pSubcategory 
   */
  static toCreate() {
    return this.shape({
      categoryId: null,
      name: '',
    })
  }

  /**
   * 
   */
  static update(pSubcategory) {
    return this.shape(pSubcategory).save()
  }
}