//
import PCategoryService from "@/services/api/Provider/PCategory";
import PProviderService from "@/services/api/Provider/PProvider";
import GRegionService from "@/services/api/General/GRegion";
import GProvinceService from "@/services/api/General/GProvince";
import GCommuneService from "@/services/api/General/GCommune";
//import PServiceService from '@/services/api/Provider/PService'
import PSizeService from "@/services/api/Provider/PSize";
import PSubcategoryService from "@/services/api/Provider/PSubcategory";

//
export default {
  name: "v-view-search",
  data() {
    return {
      mobilePropsTitle: {
        fontSize: "22px !important",
      },
      nomobilePropsTitle: {
        fontSize: "50px !important",
      },
      mobilePropsP: {
        fontSize: "22px !important",
      },
      noMobilePropsP: {
        fontSize: "22px !important",
      },
      noMobileSearch: {
        marginLeft: "8%",
      },
      parallaxPropsSearch: {
        src: require("@/assets/images/banner-proveedores-contacto.jpg"),
        height: 550,
        alt: "IMAGEN DE FONDO",
      },
      parallaxPropsSearch2: {
        src: require("@/assets/images/banner-proveedores-contacto.jpg"),
        height: 250,
        alt: "IMAGEN DE FONDO",
      },
      busy: false,
      dialogs: {
        required: false,
      },
      page: 1,
      pCategories: {
        data: [],
        length: 0,
      },
      pSubcategories: {
        data: [],
        length: 0,
      },
      gRegions: {
        data: [],
        length: 0,
      },
      gProvinces: {
        data: [],
        length: 0,
      },
      gCommunes: {
        data: [],
        length: 0,
      },
      pProviders: {
        data: [],
        length: 0,
      },
      pProvidersSearch: {
        data: [],
        length: 0,
      },
      pServices: {
        data: [],
        length: 0,
      },
      pSizes: {
        data: [],
        length: 0,
      },
      filters: {
        descending: true,
        categoryId: null,
        categoryName: null,
        subcategoryId: null,
        subcategoryName: null,
        communeId: null,
        communeName: null,
        provinceId: null,
        provinceName: null,
        regionId: null,
        regionName: null,
        scoreId: null,
        search: "",
        serviceId: null,
        providerId: null,
        sizeId: null,
        sizeName: null,
        limit: 15,
        page: 1,
      },
      search: "",
      filtrosPanel: [],
      selected: {
        categories: [],
        communes: [],
        provinces: [],
        regions: [],
        services: [],
        sizes: [],
        subcategories: [],
        providers: [],
      },
      arrow: true,
      arrowFaena: false,
    };
  },
  computed: {
    isMobileTitle() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return this.mobilePropsTitle;
      } else {
        return this.nomobilePropsTitle;
      }
    },
    isMobileP() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return this.mobilePropsP;
      } else {
        return this.noMobilePropsP;
      }
    },
    isSearchMobile() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return "";
      } else {
        return this.noMobileSearch;
      }
    },
  },
  methods: {
    getCategories() {
      PCategoryService.getAllPaginate().then((res) => {
        this.pCategories = res;
      });

      let categorias = JSON.parse(localStorage.getItem("filters")).categoryId;
      if (categorias != null) {
        let filterCatId = JSON.parse(
          localStorage.getItem("filters")
        ).categoryId;
        this.selected.categories = filterCatId.split(",").map(function (item) {
          return parseInt(item) - 1;
        });
        this.filtrosPanel.push(0);
      }
    },

    getSubcategories() {
      PSubcategoryService.getAllPaginateByCategoryId(this.filters.categoryId)
        .then(this.mapSubcategories)
        .then((res) => {
          this.pSubcategories = res;
        });
    },

    mapSubcategories(res) {
      let arr = [];

      res.data.forEach((subcategory) => {
        let index = arr.findIndex((item) => item.name == subcategory.name);
        if (index < 0) {
          arr.push(subcategory);
        } else {
          arr[index]["ids"] = [...arr[index].ids, subcategory.id];
        }
      });
      res.data = arr;

      let subcategorias = JSON.parse(
        localStorage.getItem("filters")
      ).subcategoryId;
      if (subcategorias != null) {
        let filterSubcatId = JSON.parse(
          localStorage.getItem("filters")
        ).subcategoryId;
        this.selected.subcategories = filterSubcatId
          .split(",")
          .map(function (item) {
            let indice = arr.findIndex(
              (element) =>
                element.id ==
                element.ids.map(function (e) {
                  if (e == item) return e;
                })
            );
            if (indice >= 0) {
              return parseInt(indice);
            }
          });
        this.selected.subcategories = this.selected.subcategories.filter(
          function (x) {
            return x !== undefined;
          }
        );
        this.filtrosPanel.push(1);
      }

      return res;
    },

    getSizes() {
      PSizeService.getAll(this.filters).then((res) => {
        this.pSizes = {
          data: res,
        };
      });

      let tamaños = JSON.parse(localStorage.getItem("filters")).sizeId;
      if (tamaños != null) {
        let filterSizeId = JSON.parse(localStorage.getItem("filters")).sizeId;
        this.selected.sizes = filterSizeId.split(",").map(function (item) {
          return parseInt(item) - 1;
        });
        this.filtrosPanel.push(2);
      }
    },

    getRegions() {
      GRegionService.getAllPaginate().then((res) => {
        this.gRegions = res;
      });

      let regiones = JSON.parse(localStorage.getItem("filters")).regionId;
      if (regiones != null) {
        let filterRegionId = JSON.parse(
          localStorage.getItem("filters")
        ).regionId;
        this.selected.regions = filterRegionId.split(",").map(function (item) {
          return parseInt(item) - 1;
        });
        this.filtrosPanel.push(3);
      }
    },

    getProvinces() {
      GProvinceService.getAllPaginateByRegionId(this.filters.regionId)
        .then(this.mapProvinces)
        .then((res) => {
          this.gProvinces = res;
        });
    },

    mapProvinces(res) {
      let arr = [];

      res.data.forEach((province) => {
        let index = arr.findIndex((item) => item.name == province.name);
        if (index < 0) {
          arr.push(province);
        } else {
          arr[index]["ids"] = [...arr[index].ids, province.id];
        }
      });
      res.data = arr;

      let provincias = JSON.parse(localStorage.getItem("filters")).provinceId;
      if (provincias != null) {
        let filterProvinceId = JSON.parse(
          localStorage.getItem("filters")
        ).provinceId;
        this.selected.provinces = filterProvinceId
          .split(",")
          .map(function (item) {
            let indice = arr.findIndex((element) => element.id == item);
            if (indice >= 0) {
              return parseInt(indice);
            }
          });
        this.selected.provinces = this.selected.provinces.filter(function (x) {
          return x !== undefined;
        });
        this.filtrosPanel.push(4);
      }

      return res;
    },

    getCommunes() {
      if (this.filters.regionId != null && this.filters.provinceId != null) {
        GCommuneService.getAllPaginateByRegionIdAndByProvinceId(
          this.filters.regionId,
          this.filters.provinceId
        )
          .then(this.mapCommunes)
          .then((res) => {
            this.gCommunes = res;
          });
      } else {
        if (this.filters.regionId != null) {
          GCommuneService.getAllPaginateByRegionId(this.filters.regionId)
            .then(this.mapCommunes)
            .then((res) => {
              this.gCommunes = res;
            });
        }
        if (this.filters.provinceId != null) {
          GCommuneService.getAllPaginateByProvinceId(this.filters.provinceId)
            .then(this.mapCommunes)
            .then((res) => {
              this.gCommunes = res;
            });
        }
      }
    },

    mapCommunes(res) {
      let arr = [];

      res.data.forEach((commune) => {
        let index = arr.findIndex((item) => item.name == commune.name);
        if (index < 0) {
          arr.push(commune);
        } else {
          arr[index]["ids"] = [...arr[index].ids, commune.id];
        }
      });
      res.data = arr;

      let provincias = JSON.parse(localStorage.getItem("filters")).communeId;
      if (provincias != null) {
        let filterCommuneId = JSON.parse(
          localStorage.getItem("filters")
        ).communeId;
        this.selected.communes = filterCommuneId
          .split(",")
          .map(function (item) {
            let indice = arr.findIndex((element) => element.id == item);
            if (indice >= 0) {
              return parseInt(indice);
            }
          });
        this.selected.communes = this.selected.communes.filter(function (x) {
          return x !== undefined;
        });
        this.filtrosPanel.push(5);
      }

      return res;
    },

    getCategoryId(index) {
      return this.pCategories.data[index].id;
    },
    getCategoryName(index) {
      return this.pCategories.data[index].name;
    },

    getSubcategoriesId(index) {
      return this.pSubcategories.data[index].ids.join(",");
    },

    getRegionId(index) {
      return this.gRegions.data[index].id;
    },

    getProvinceId(index) {
      return this.gProvinces.data[index].id;
    },

    getCommuneId(index) {
      return this.gCommunes.data[index].id;
    },

    getParams() {
      if (this.$route.query.services) {
        this.search = this.$route.query.services.split(",").join(" ");
      }
    },

    async getProviders() {
      this.busy = true;
      this.updateLSFilters(this.filters);
      await PProviderService.getPublicProviders(this.filters).then((res) => {
        this.pProviders = res;
      });
      this.arrowFaena = false;
      this.arrow = true;
      this.busy = false;
    },

    async getProvidersFaena() {
      this.busy = true;
      await PProviderService.getPublicProvidersFaena(this.filters).then(
        (res) => {
          this.pProviders = res;
        }
      );
      this.arrowFaena = true;
      this.arrow = false;
      this.busy = false;
    },

    buscarPS() {
      this.filters.search = this.search;
      this.getProviders();
    },

    clearPS() {
      this.filters.search = null;
      this.getProviders();
    },

    getSizeId(index) {
      return this.pSizes.data[index].id;
    },

    setLocalStorage() {
      this.filters = JSON.parse(localStorage.getItem("filters") || "{}");
      this.getProviders();
    },

    handleProviderClick(pProvider) {
      if (this.isLogged) {
        window.open(pProvider.permalink, "_blank");
      } else {
        this.dialogs.required = true;
      }
    },

    updateLSFilters(filters) {
      localStorage.setItem("filters", JSON.stringify(filters));
      if (this.filters.search != null) {
        this.search = this.filters.search;
      }
    },
  },
  created() {
    this.setLocalStorage();
    this.getCategories();
    this.getSubcategories();
    this.getSizes();
    this.getRegions();
    this.getProvinces();
    this.getCommunes();
    this.getParams();
  },
  mounted() {
    this.selected.providers = this.$route.params.search;
  },
  watch: {
    "filters.limit"() {
      this.getProviders();
    },

    "filters.page"() {
      this.getProviders();
    },

    "selected.categories"(categories) {
      this.filters.categoryId = categories.length
        ? categories.map(this.getCategoryId).join()
        : null;
      this.getSubcategories();
      this.getProviders();
    },

    "selected.subcategories"(subcategories) {
      this.filters.subcategoryId = subcategories.length
        ? subcategories.map(this.getSubcategoriesId).join()
        : null;
      this.getProviders();
    },

    "selected.sizes"(sizes) {
      this.filters.sizeId = sizes.length
        ? sizes.map(this.getSizeId).join()
        : null;
      this.getProviders();
    },

    "selected.regions"(regions) {
      this.filters.regionId = regions.length
        ? regions.map(this.getRegionId).join()
        : null;
      this.getProvinces();
      this.getCommunes();
      this.getProviders();
    },

    "selected.provinces"(provinces) {
      this.filters.provinceId = provinces.length
        ? provinces.map(this.getProvinceId).join()
        : null;
      this.getCommunes();
      this.getProviders();
    },

    "selected.communes"(communes) {
      this.filters.communeId = communes.length
        ? communes.map(this.getCommuneId).join()
        : null;
      this.getProviders();
    },

    "selected.services"(services) {
      this.filters.serviceId = services.length ? services.join() : null;
      this.getProviders();
    },

    "selected.providers"(providers) {
      this.filters.providerId = providers.length ? providers.join() : null;
      this.getProviders();
    },

    // "gProvinces.data"(data) {
    //   this.gProvinces.length = data.length;
    //   this.selected.provinces = [];
    // },

    // "gCommunes.data"(data) {
    //   this.gCommunes.length = data.length;
    //   this.selected.communes = [];
    // },
  },
};
