// app plugin
export default {
  install: function (Vue) {
    Vue.mixin({
      computed: {

        /**
         * 
         */
        isAdmin() {
          return this.isLogged && this.$store.state.UserModule.data.profile.code === 'system'
        },

        /**
         * 
         */
        isLogged() {
          return this.getGetter('UserModule/hasData')
        },

        /**
         * 
         */
        isNotLogged() {
          return this.getGetter('UserModule/notData')
        },

        /**
         * 
         */
        isMiningCompany() {
          return this.isLogged && this.$store.state.UserModule.data.profile.code === 'mining_company'
        },

        /**
         * 
         */
        isMiningCompanyAdmin() {
          return this.isLogged && this.$store.state.UserModule.data.role.code === 'mining_company_admin'
        },

        /**
         * 
         */
        isProvider() {
          return this.getGetter('ProviderModule/hasData')
        },
        
        /**
         * 
         */
        $provider() {
          return this.$store.state.ProviderModule.data
        },

        /**
         * 
         */
        $user() {
          return this.$store.state.UserModule.data
        }
      },
      methods: {

        /**
         * 
         * @param {*} refName 
         */
        getGetter(getterName) {
          return this.$store.getters[getterName]
        },
        
        /**
         * 
         * @param {*} attr 
         * @param {*} promise 
         */
        getRef(refName) {
          return this.$refs[refName]
        },

        /**
         * 
         */
        goTop() {
          // console.log('go-top')
          this.$vuetify.goTo(0, {
            duration: 0,
            offset: 0,
            easing: 'linear',
          })
        },

        /**
         * 
         * @param {*} text 
         * @param {*} confirmText 
         * @param {*} onclick 
         */
        $confirm(text, confirmText, onclick) {
          this.$toast(text, {
            closeText: 'cancelar',
            color: 'warning',
            dismissible: true,
            icon: 'mdi-alert',
            showClose: true,
            slot: [ this.$createElement('button', { class: 'v-btn v-btn--flat v-btn--depressed v-size--default warning darken-4 py-6', on: { click: onclick } }, [ confirmText ]) ],
            timeout: 300000,
            x: 'center'
          })
        },
      },
    })
  }
}
