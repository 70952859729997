// requires
import Report from '@/services/reports/Report'

/**
 * 
 */
export default class PProviderReport extends Report {

  // /**
  //  * 
  //  */
  // getAmbiteText() {
  //   if (this.data.score < 10.0) {
  //     return 'Sin cinturón'
  //   }
  //   if (this.data.score <= 50.0) {
  //     return 'Cinturón blanco'
  //   }
  //   if (this.data.score <= 79.0) {
  //     return 'Cinturón verde'
  //   }
  //   return 'Cinturón negro'
  // }

  /**
   * 
   */
   getAmbiteText() {
    return this.data.scoreName
  }

  /**
   * cambiar la logica y esto tiene que venir del back
   */
  getQualificationText() {
    if (this.data.score < 10.0) {
      return this.getAmbiteText()+' al estar en el rango de 0% a 9%'
    }
    if (this.data.score <= 50.0) {
      return this.getAmbiteText()+' al estar en el rango de 10% a 50%'
    }
    if (this.data.score <= 79.0) {
      return this.getAmbiteText()+' al estar en el rango de 51% a 79%'
    }
    return this.getAmbiteText()+' al estar en el rango de 80% a 100%'
  }

  /**
   * 
   */
  getSummaryText = () => {

    // required
    const { name, score } = this.data
    let lineIntro     = 'A continuación se le entrega registro de que la evaluación diagnóstica del cumplimiento de los requisitos,'
    let lineProvider  = (this.data.isProvider ? 'usted como representante de' : 'la empresa') + ': ' + name + ','
    let lineScore     = 'ha cumplido con ' + score + '% como porcentaje global.'
    let lineCinte     = 'Lo anterior lo clasifica como ' + this.getQualificationText() + ' en su porcentaje global.'
    let lineNext      = 'El detalle de ' + (this.data.isProvider ? 'sus' : 'las') +' respuestas se encuentra a continuación por categoría evaluada:'

    // result
    return `${ lineIntro } ${ lineProvider } ${ lineScore } ${ lineCinte } ${ lineNext }`
  }

  /**
   * 
   */
  render() {
    this.renderHeader()
    this.renderTitle()
    this.renderProviderInfo()
    let next = this.renderReportSummary()
    this.renderReportDetails(next)
  }

  /**
   * 
   */
  renderHeader() {

    // required
    var now = new Date(Date.now()).toLocaleDateString()

    // write
    this.doc.setFontSize(8)
    this.doc.text('ESPACIO INDUSTRIAL MINERO', 30, 40)
    this.doc.text(now, 520, 40)
  }

  /**
   * 
   */
  renderPageHeader(pageNumber) {

    // required
    var now = new Date(Date.now()).toLocaleDateString()

    this.doc.setFontSize(8)
    this.doc.text('REPORTE PROVEEDORES REGIONALES', 30, 40)
    this.doc.text('Pagina ' + pageNumber, 282, 40)
    this.doc.text(now, 520, 40)
  }

  /**
   * 
   */
  renderReportSummary() {

    // required
    //console.log("this.data",this.data)
    const { criteria } = this.data
    const { questions } = this.data
  
    let next = 0

    // write
    this.doc.setFontSize(10)
    next = this.wrap('Para la asignación de un peso relativo a las preguntas contenidas en la autoevaluación de proveedores, se establece por:', 15, 30, 15*14.5, 530) + 1
    next = this.wrap('     -  Criterios: correspondiente a los ' + criteria.length + ' criterios establecidos (' + criteria.join(', ') + ').', 15, 30, next, 530) + 1
    next = this.wrap('     -  Practicas: correspondiente a las preguntas contenidas en cada criterio. En total se consideran '+ questions +' preguntas.', 15, 30, next, 530) + 15
    next = this.wrap('En base a lo anterior se dispone:', 15, 30, next, 530) + 1
    next = this.wrap('     -  Todas las preguntas tienen la misma ponderación o peso relativo.', 15, 30, next, 530) + 15
    next = this.wrap(this.getSummaryText().replace(/\n/g, ''), 15, 30, next, 530) + 1

    // result
    return next
  }

  /**
   * 
   */
  renderReportDetails(next) {

    // required
    const { images } = this.data
    const { questions } = this.data
    if(questions==48){
    // write
    this.doc.setFontSize(12)
    this.doc.text('1. Sistema integrado de gestión:', 30, next+15)
    this.doc.addImage(images[0], 'PNG', 30, next, 400, 200)

    this.doc.text('2. Programas:', 30, next+200)
    this.doc.addImage(images[1], 'PNG', 30, next+185, 400, 200)

    // PAGE 2
    this.doc.addPage()
    this.renderPageHeader(2)

    this.doc.setFontSize(12)
    this.doc.text('3. Plataformas de abastecimiento y licitaciones:', 30, 80)
    this.doc.addImage(images[2], 'PNG', 30, 65, 400, 200)

    this.doc.text('4. Global HSEC(Health Safety Environment Community):', 30, 260)
    this.doc.addImage(images[3], 'PNG', 30, 245, 400, 200)

    this.doc.text('5. Criterios especiales para la competitividad:', 30, 440)
    this.doc.addImage(images[4], 'PNG', 30, 425, 400, 200)

    this.doc.text('6. Capacidad técnica:', 30, 620)
    this.doc.addImage(images[5], 'PNG', 30, 605, 400, 200)

    // PAGE 3
    this.doc.addPage()
    this.renderPageHeader(3)

    this.doc.setFontSize(12)
    this.doc.text('7. Tecnología:', 30, 80)
    this.doc.addImage(images[6], 'PNG', 30, 65, 400, 200)
    }else{
      // write
    this.doc.setFontSize(12)
    this.doc.text('1. Gestión de Riesgos de Procesos (GRP):', 30, next+15)
    this.doc.addImage(images[0], 'PNG', 30, next, 400, 200)

    this.doc.text('2. Protocolos de cumplimiento Covid-19:', 30, next+200)
    this.doc.addImage(images[1], 'PNG', 30, next+185, 400, 200)

    // PAGE 2
    this.doc.addPage()
    this.renderPageHeader(2)

    this.doc.setFontSize(12)
    this.doc.text('3. Plan de Emergencia:', 30, 80)
    this.doc.addImage(images[2], 'PNG', 30, 65, 400, 200)

    this.doc.text('4. Protocolos de conducta comercial y convivencia armónica con comunidades:', 30, 260)
    this.doc.addImage(images[3], 'PNG', 30, 245, 400, 200)

    this.doc.text('5. Ubicación empresas de alojamiento:', 30, 440)
    this.doc.addImage(images[4], 'PNG', 30, 425, 400, 200)

    this.doc.text('6. Proceso de Habilitación Proveedores Locales:', 30, 620)
    this.doc.addImage(images[5], 'PNG', 30, 605, 400, 200)
    }

  }

  /**
   * 
   */
  renderProviderInfo = () => {

    // required
    const { category, name, rut } = this.data

    // write
    this.doc.setFontSize(10)
    this.doc.text('Proveedor: ' + name, 30, 15*9)
    this.doc.text('Rut: ' + rut, 30, 15*10)
    this.doc.text('Categoría: ' + category, 30, 15*11)
    this.doc.text('Ámbito: ' + this.getAmbiteText(), 30, 15*12)
    this.doc.line(30, 15*13, 560, 15*13)
  }

  /**
   * 
   */
  renderTitle() {

    // write
    this.doc.setFontSize(16)
    this.doc.text('Reporte Proveedores Regionales', 180, 80)
    this.doc.setFontSize(12)
    this.doc.text('Evaluación de cumplimiento de los criterios y requisitos generales', 120, 100)
  }

  /**
   * 
   */
  get title() {
    return 'REPORTE_PROVEEDORES_REGIONALES-' + this.data.name.toUpperCase()
  }
}