//
import axios from 'axios'
import { Model } from 'model-wrapper'

//
export default class extends Model {

  /**
   * 
   */
  static adapter() {
    return axios
  }

  /**
   * 
   */
  static baseUrl() {
    return process.env.VUE_APP_API_ROUTE
  }
}