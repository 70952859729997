//
import Lang from '@/services/lang/Lang'

/**
 * 
 */
export default class PProviderLang extends Lang {

  /**
   * 
   */
  defineAreas() {
    return this
      .set('The areas.', 'En el contacto ')
      .set('.providerAreaId field is required.', ', el area es requerida.')
  }
  
  /**
   * 
   */
  defineVideo() {
    return this
      .set('The video url field is required.', 'La dirección de URL del video es requerida.')
  }

  /**
   * 
   */
  static toAll() {
    return this
      .shape()
      .defineAreas()
      .defineVideo()
  }
}