//
export default {
  name: 'app-layout-frame',
  computed: {

    /**
     * 
     */
    containerOptions() {
      return {
        class: this.container,
      }
    },

    /**
     * 
     */
    sectionOptions() {
      return {
        class: this.section,
      }
    },
  },
  props: {

    /**
     * 
     */
    container: {
      type: String,
    },

    /**
     * 
     */
    containerProps: {
      type: Object,
      default: () => ({
        class: 'mb-16',
      })
    },

    /**
     * 
     */
    parallaxProps: {
      type: Object,
      default: () => ({
        src: require("@/assets/images/banner-proveedores-contacto.jpg"),
        height: 550,
      })
    },

    /**
     * 
     */
    parallaxProps2: {
      type: Object,
      default: () => ({
        src: require("@/assets/images/banner-proveedores-contacto.jpg"),
        height: 250,
      })
    },

    /**
     * 
     */
    rowProps: {
      type: Object,
      default: () => ({
        
      })
    },

    /**
     * 
     */
    section: {
      type: String,
    },

    /**
     * 
     */
    sectionProps: {
      type: Object,
      default: () => ({
        class: 'pb-16',
      })
    },

    /**
     * 
     */
    title: {
      type: String,
    },

    /**
     * 
     */
    titleProps: {
      type: Object,
      default: () => ({
        //class: 'display-2 primary px-2 d-inline text-uppercase white--text rounded elevation-1',        
      })
    },
  },
}