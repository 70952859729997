//
import store from "@/store";

/**
 *
 * @param {*} to
 * @param {*} from
 * @param {*} next
 */
export default function auth(to, from, next) {
  //
  // console.log(store)

  //
  let isLogged = !!store.state.AuthModule.data.accessToken;
  let isPublic = !!routes.includes(to.name);

  //
  if (!isLogged && !isPublic) {
    next("/auth");
  }

  next();
}

/**
 *
 */
export const routes = [
  "about",
  "Proyecto Red Asociativa",
  "Cumbre Industrial Minera",
  "Eventos", // eliminar en producción
  "Inscripcion", // eliminar en producción
  "contact",
  "home",
  "search",
  "zoom-redirect",
  "g-web-page",
  "auth-login",
  "auth-register",
  "auth-recovery",
  "auth-reset",
];
