//
import Lang from '@/services/lang/Lang'

/**
 * 
 */
export default class AthRegisterLang extends Lang {

  /**
   * 
   */
  defineProvider() {
    return this
      .set('The commune id field is required.'      , 'La comuna es requerida.')
      .set('The business name field is required.'   , 'El nombre es requerido.')
      .set('The address field is required.'         , 'La dirección es requerida.')
      .set('The latitude field is required.'        , 'La latitud es requerida.')
      .set('The longitude field is required.'       , 'La longitud es requerida.')
      .set('The size id field is required.'         , 'El tamaño es requerido.')
      .set('The category id field is required.'     , 'La categoría es requerida.')
      .set('The description field must be present.' , 'La descripción es requerida.')
      .set('The web url field must be present.'     , 'La dirección web es requerida.')
      .set('El campo g-recaptcha-response es obligatorio.', 'Verifique que no es un robot.')
  }

  /**
   * 
   */
  defineUser() {
    return this
      .set('The address field is required.', 'La dirección es requerida.')
  }

  /**
   * 
   */
  static toAll() {
    return this
      .shape()
      .defineProvider()
      .defineUser()
  }
}