// required modules
import axios from "axios";
import Service from "@/services/api/Service";
import GUserAccountModel from "@/models/General/GUserAccount";

/**
 * This class describes a GUserAccount service.
 *
 * @class GUserAccount (name)
 */
export default class GUserAccountService extends Service {
  /**
   *
   */
  static disable(gUserAccount) {
    return gUserAccount
      .many("disable")
      .attach(gUserAccount.reduce(["observation"]));
  }

  /**
   *
   */
  static enable(gUserAccount) {
    return gUserAccount.many("enable").attach();
  }

  /**
   *
   */
  static getBy(opts = {}) {
    console.log({ opts });
    return this.query()
      .where("userId", opts.gUserAccountId)
      .page(opts.page)
      .limit(opts.limit)
      .paginate();
  }

  static getBySearch(search = "") {
    return this.query().where("search", search).paginate();
  }

  /**
   *
   */
  static getById(id) {
    return this.query().find(id);
  }

  /**
   *
   */
  static getByToken(token) {
    return axios.request({
      method: "get",
      url: `${process.env.VUE_APP_API_ROUTE}/user_account/me`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  /**
   * Define the GUserAccount model.
   *
   * @return {Function} GUserAccount resource.
   */
  static model() {
    return GUserAccountModel;
  }

  /**
   *
   */
  static requestResetPassword(gUserAccount) {
    return gUserAccount
      .requestResetPassword()
      .attach(gUserAccount.toRequestResetPassword());
  }

  /**
   *
   */
  static resetPassword(gUserAccount) {
    return gUserAccount.many("reset_password").attach({
      email: gUserAccount.email,
      password: gUserAccount.password,
      token: gUserAccount.token,
    });
  }
  /**
   *
   */
  static changePassword(gUserAccount, newPassword) {
    return gUserAccount.many("change_password").attach({
      rut: gUserAccount.rut,
      currentPassword: gUserAccount.currentPassword,
      newPassword: newPassword,
    });
  }

  /**
   *
   */
  static toRecovery() {
    return this.shape({
      rut: "",
    });
  }

  /**
   *
   */
  static verify(opts = {}) {
    return this.shape({ id: "verify" }).attach(opts);
  }
}
