// required modules
import Model from '@/models/Model'

/**
 * This class describes a GSummary model.
 *
 * @class GSummary
 */
export default class GSummaryModel extends Model {

  /**
   * Define the GSummary resource.
   *
   * @return {String} GSummary endpoint name.
   */
  static resource() {
    return 'summary'
  }
}