// service & model
import Service from "@/services/api/Service";
import AthRegisterModel from "@/models/Auth/AthRegister";
import AthRegisterLang from "@/services/lang/Auth/AthRegister";

/**
 * This class describes a AthRegister service.
 *
 * @class AthRegisterService (name)
 */
export default class AthRegisterService extends Service {
  /**
   *
   */
  static langs() {
    return AthRegisterLang.toAll();
  }

  /**
   *
   */
  static model() {
    return AthRegisterModel;
  }

  /**
   *
   * @param {*} athRegister
   */
  // static natural(athRegister) {
  static natural() {
    // return athRegister.natural().attach(athRegister.toNatural())
    return Promise.resolve({
      providerId: 1,
      message: "Proveedor registrado con éxito",
    });
  }

  /**
   *
   * @param {*} athRegister
   */
  static provider(athRegister) {
    return athRegister.provider().attach(athRegister.toProvider());
  }

  /**
   *
   * @param {*} athRegister
   */
  static register = (athRegister) => {
    return athRegister.is_provider
      ? this.provider(athRegister)
      : this.natural(athRegister);
  };

  /**
   *
   * @returns {*} athRegister
   */
  static toRegisterReset() {
    return this.shape({
      "g-recaptcha-response": "",
      address: "",
      addressBranch:"",
      businessName: "",
      categoryId: 1,
      communeId: null,
      communeIdBranch: null,
      description: "",
      descriptionExp: "",
      email: "",
      is_provider: true,
      latitude: 0,
      longitude: 0,
      name: "",
      password: "",
      phone: "",
      provinceId: null,
      regionId: null,
      repeat_password: "",
      rut: "",
      sizeId: null,
      webUrl: "",
    });
  }

  static toRegisterResetB() {
    return this.shape({      
      categoryId: 1,
      legalRepresentative: {
        name: "",
        rut: "",
        email: "",
        phone: "",
      },
      is_provider: true,
      sizeId: null,
      webUrl: "",
    });
  }

  static toRegisterResetC() {
    return this.shape({
      logo: {},
        is_provider: true,
        instagram: "",
        facebook: "",
        linkedin: "",
        videoUrl: "",
        brochure: {},
    });
  }


  static toRegister(form) {
    // return this.shape(form).save();
    return this.shape(form).save();
  }
  /**
   *
   * @returns {*} athRegister
   */
  static toTest() {
    return this.shape({
      "g-recaptcha-response": "",
      address: "Dirección, #001",
      businessName: "Juan Perez SPA",
      categoryId: 1,
      communeId: null,
      description: "Juan Perez SPA - Contratista",
      email: "juanperez@yopmail.com",
      is_provider: false,
      latitude: 0,
      longitude: 0,
      name: "Juan Perez",
      password: "secret",
      phone: 987654321,
      provinceId: null,
      regionId: null,
      repeat_password: "secret",
      rut: "1111111-1",
      sizeId: 1,
      webUrl: "http://www.google.cl",
    });
  }
}
