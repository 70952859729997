// required modules
import Service from '@/services/api/Service'
import PSizeModel from '@/models/Provider/PSize'

/**
 * This class describes a PSize service.
 *
 * @class PSize (name)
 */
export default class PSizeService extends Service {

  /**
   * Define the PSize model.
   *
   * @return {Function} PSize resource.
   */
  static model() {
    return PSizeModel
  }

  /**
   * 
   */
  static getAll() {
    return this
      .query()
      .limit(-1)
      .get()
  }

  /**
   * 
   */
  static getAllPaginate() {
    return this
      .query()
      .paginate()
  }
}