// required modules
import Service from '@/services/api/Service'
import GListWebPageRedModel from '@/models/General/GListWebPageRed'

/**
 * This class describes a GWebPage service.
 *
 * @class GWebPage (name)
 */
export default class GListWebPageService extends Service {

  /**
   * 
   */
  static create(pCategory) {
    return this.shape(pCategory).save()
    //return this.shape('new_web_page').transfer(pCategory.form(['code','content','name', 'active']))
  }

  /**
   * Define the PCategory model.
   *
   * @return {Function} PCategory resource.
   */
  static model() {
    return GListWebPageRedModel
  }

  /**
   * 
   */
  static getAll() {
    return this
      .query()
      //.limit(-1) //genera conflicto.
      .get()
  }

  /**
   * 
   */
  static getBy(opts = {}) {
    return this
      .query()
      .page(opts.page)
      .limit(opts.limit)
      .paginate()
  }

  /**
   * 
   */
  static getAllPaginate() {
    return this
      .query()
      .limit(-1)
      .paginate()
  }

  /**
   * 
   * @param {*} pCategory 
   */
  static toCreate() {
    return this.shape({
      name: '',
      active: false,
      code:'red',
      content: '',
    })
  }

  /**
   * 
   */
  static update(pCategory) {
    return this.shape(pCategory).save()
    //return this.shape('new_web_page').transfer(pCategory.form(['code','content','name', 'active']))
  }
}