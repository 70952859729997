// required modules
import Model from '@/models/Model'

/**
 * This class describes a PCategory model.
 *
 * @class PCategory
 */
export default class PCategoryModel extends Model {

  /**
   * Define the PCategory resource.
   *
   * @return {String} PCategory endpoint name.
   */
  static resource() {
    return 'category'
  }
}