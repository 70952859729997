// required modules
import Service from '@/services/api/Service'
import GProvinceModel from '@/models/General/GProvince'

/**
 * This class describes a GProvince service.
 *
 * @class GProvince (name)
 */
export default class GProvinceService extends Service {

  /**
   * Define the GProvince model.
   *
   * @return {Function} GProvince resource.
   */
  static model() {
    return GProvinceModel
  }

  static getAllPaginateByRegionId(gRegionId) {
    return this
      .query()
      .where('regionId', gRegionId)
      .limit(-1)
      .paginate()
  }

  /**
   * 
   */
  static getByRegionId() {
    return Promise.resolve({
      data: [],
    })
  }
}