//
import { VwLayoutForm } from 'vuetify-wrappers/v2'

//
export default {
  name: 'app-layout-form',
  extends: VwLayoutForm,
  props: {

    /**
     * 
     */
    actionsProps: {
      type: Object,
      default: () => ({
        class: 'justify-center',
      })
    },

    /**
     * 
     */
    buttonProps: {
      type: Object,
      default: () => ({
        text: true,
      })
    },

    /**
     * 
     */
    containerProps: {
      type: Object,
      default: () => ({
        class: 'px-2',
        fluid: true,
      })
    },

    /**
     * 
     */
    contentRowProps: {
      type: Object,
      default: () => ({
        noGutters: true,
      })
    },

    /**
     * 
     */
    fieldsetProps: {
      type: Object,
      default: () => ({
        style: {
          border: 0,
        },
      })
    },

    /**
     * 
     */
    submitProps: {
      type: Object,
      default: () => ({
        color: 'primary',
      })
    },

    /**
     * 
     */
    titleProps: {
      type: Object,
      default: () => ({
        class: 'px-2 grey--text text--darken-2 text-uppercase title',
      })
    },
  },
}