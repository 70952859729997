// required modules
import Model from '@/models/Model'

/**
 * This class describes a PSize model.
 *
 * @class PSize
 */
export default class PSizeModel extends Model {

  /**
   * Define the PSize resource.
   *
   * @return {String} PSize endpoint name.
   */
  static resource() {
    return 'size'
  }
}