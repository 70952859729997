// requires
import jsPDF from "jspdf";

/**
 * 
 */
export default class Report {

  /**
   * 
   */
  constructor(data) {
    this.doc  = new jsPDF('p', 'pt')
    this.data = data
  }

  /**
   * 
   */
  static from(data) {
    return new this(data)
  }

  /**
   * 
   */
  print() {
    this.render()
    this.doc.save(this.title.concat('.pdf'))
  }

  /**
   * 
   */
  wrap(text, size, left, top, width) {

    // required
    var line        = top
    var lineHeight  = size
    var leftMargin  = left
    var wrapWidth   = width
    var longString  = text
    var splitText = this.doc.splitTextToSize(longString, wrapWidth)

    // set an recalculate line
    for (var i = 0, length = splitText.length; i < length; i++) {
      this.doc.text(splitText[i], leftMargin, line)
      line = lineHeight + line
    }  
    
    // last line
    return line
  }

  /**
   * 
   */
  get title() {
    return 'example'
  }
}