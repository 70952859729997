//
//
export default {
  name: "v-view-home",
  data() {
    return {
      items: [
        { imagen: require("@/assets/images/1-cumbre-industrial-minera.png") },
        { imagen: require("@/assets/images/2-EIM-COLOR.png") },
        { imagen: require("@/assets/images/3-logo-CIDERE.png") },
        { imagen: require("@/assets/images/4-CMP.jpg") },
        { imagen: require("@/assets/images/5-MLP.jpg") },
        { imagen: require("@/assets/images/6-TECK.png") },
        { imagen: require("@/assets/images/7-LOGO-CRDP.png") },
        { imagen: require("@/assets/images/8-logo-ULS.png") },
        { imagen: require("@/assets/images/9-logo-seremi.jpg") },
        { imagen: require("@/assets/images/10-logo-flex.png") },
      ],
      activeSlide: 0,
      autoplayInterval: null,
      transitioning: false,
    };
  },
  computed: {
    displayItems() {
      // Clona el primer y el último elemento para crear un efecto de bucle
      return [this.items[this.items.length - 1], ...this.items, this.items[0]];
    },
    displayedSlide() {
      return this.activeSlide + 1; // Ajusta el índice para la vista con elementos clonados
    },
  },
  mounted() {
    this.startAutoplay();
  },
  methods: {
    inscribeteClick() {
      if (this.isLogged) {
        this.$router.push("/eventos");
      } else {
        this.$router.push("/auth");
      }
    },
    startAutoplay() {
      this.autoplayInterval = setInterval(() => {
        this.nextSlide();
      }, 2000); // Cambia el slide cada 3 segundos
    },
    nextSlide() {
      if (this.transitioning) return;
      this.transitioning = true;
      this.activeSlide++;
      this.updateDisplayedSlide();

      if (this.activeSlide === this.items.length) {
        setTimeout(() => {
          this.transitioning = false;
          this.activeSlide = 0;
          this.resetTransition();
        }, 200); // Ajusta este valor según la duración de tu transición
      } else {
        setTimeout(() => {
          this.transitioning = false;
        }, 200); // Ajusta este valor según la duración de tu transición
      }
    },
    prevSlide() {
      if (this.transitioning) return;
      this.transitioning = true;
      this.activeSlide--;
      this.updateDisplayedSlide();

      if (this.activeSlide < 0) {
        setTimeout(() => {
          this.transitioning = false;
          this.activeSlide = this.items.length - 1;
          this.resetTransition();
        }, 200);
      } else {
        setTimeout(() => {
          this.transitioning = false;
        }, 200);
      }
    },
    updateDisplayedSlide() {
      this.$refs.slideGroup.$el.querySelector(
        ".v-slide-group__content"
      ).style.transition = "transform 0.3s ease";
      this.displayedSlide = this.activeSlide + 1;
    },
    resetTransition() {
      this.$refs.slideGroup.$el.querySelector(
        ".v-slide-group__content"
      ).style.transition = "none";
      this.displayedSlide = this.activeSlide + 1;
      this.$nextTick(() => {
        this.$refs.slideGroup.$el.querySelector(
          ".v-slide-group__content"
        ).style.transition = "";
      });
    },
    handleSlideChange(newIndex) {
      if (this.transitioning) return;
      if (newIndex === 0) {
        this.activeSlide = this.items.length - 1;
        this.resetTransition();
      } else if (newIndex === this.displayItems.length - 1) {
        this.activeSlide = 0;
        this.resetTransition();
      } else {
        this.activeSlide = newIndex - 1;
      }
    },
    stopAutoplay() {
      if (this.autoplayInterval) {
        clearInterval(this.autoplayInterval);
        this.autoplayInterval = null;
      }
    },
  },
  beforeDestroy() {
    this.stopAutoplay();
  },
};
