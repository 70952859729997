// required modules
import Model from '@/models/Model'

/**
 * This class describes a GCommune model.
 *
 * @class GCommune
 */
export default class GCommuneModel extends Model {

  /**
   * Define the GCommune resource.
   *
   * @return {String} GCommune endpoint name.
   */
  static resource() {
    return 'commune'
  }
}