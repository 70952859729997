//
import GImageGalleryService from "@/services/api/General/GImageGallery";
import GListWebPageRedService from "@/services/api/General/GListWebPageRed";
import axios from "axios";
//
export default {
  name: "v-view-home",
  computed: {
    /**
     *
     */
    permalink() {
      return this.permalinks[this.$route.params.permalink];
    },
  },
  data: () => ({
    busy: {
      page: false,
    },
    dialogs: {
      page: false,
      webPageRed: {},
    },
    gWebPageReds: {
      data: [],
    },
    gWebPageRedsA: {
      data: [],
    },
    gWebPageRedsSeparates: [],
    gWebUp: false,

    src: "https://cdn.vuetifyjs.com/images/carousel/sky.jpg",

    model: 0,
    colors: ["primary", "secondary", "yellow darken-2", "red", "orange"],
    gImageGallery: {
      data: [],
    },
    banner: {
      data: [],
    },
  }),
  filters: {
    /**
     *
     */
    sheet: (gWebPageReds) => {
      return {
        backgroundImage: `url(${gWebPageReds.fileUrl})`,
        backgroundPosition: `center`,
        backgroundSize: `${gWebPageReds.size || "cover"}`,
      };
    },
    sheetActives: (gWebPageReds) => {
      return {
        backgroundImage: `url(${gWebPageReds.fileUrl})`,
        backgroundSize: `${gWebPageReds.size || "cover"}`,
        backgroundPosition: `center`,
        height: `295px`,
      };
    },
    sheet_Aux: (gImageGallery) => {
      console.log("gImageGallery", gImageGallery);
      console.log("backgroundImage", gImageGallery.size);
      return {
        backgroundImage: `url(${gImageGallery.fileUrl})`,
        backgroundPosition: `center center`,
        backgroundSize: "contain",
      };
    },
    sheetC: (gImageGallery) => {
      return {
        marginTop: "0px",
        backgroundImage: `url(${gImageGallery.fileUrl})`,
        backgroundPosition: `center center`,
        backgroundSize: `${gImageGallery.size || "contain"}`,
      };
    },
    sheet2: (banner) => {
      banner.fileUrl =
        process.env.VUE_APP_API_ROUTE +
        "/image_gallery/content?filePath=banners/banner-nosotros.jpg";
      console.log("backgroundImage2", banner.fileUrl);
      return {
        backgroundImage: `url(${banner.fileUrl})`,
        backgroundPosition: `center center`,
        backgroundSize: "contain",
      };
    },
    sheet2C: (banner) => {
      banner.fileUrl =
        process.env.VUE_APP_API_ROUTE +
        "/image_gallery/content?filePath=banners/banner-nosotros.jpg";
      console.log("backgroundImage2", banner.fileUrl);
      return {
        marginTop: "0px",
        backgroundImage: `url(${banner.fileUrl})`,
        backgroundPosition: `center center`,
        backgroundSize: "contain",
      };
    },
  },
  methods: {
    descargarArchivo1() {
      const url = "/docs/MILLAN2023.pdf";

      axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data]);
          const url = window.URL.createObjectURL(blob);

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "MILLAN2023.pdf");
          document.body.appendChild(link);

          link.click();

          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error("Error al descargar el archivo:", error);
        });
    },
    descargarArchivo2() {
      const url = "/docs/West2023.pdf";

      axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data]);
          const url = window.URL.createObjectURL(blob);

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "West2023.pdf");
          document.body.appendChild(link);

          link.click();

          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error("Error al descargar el archivo:", error);
        });
    },
    descargarArchivo3() {
      const url = "/docs/Cidere2023.pdf";

      axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data]);
          const url = window.URL.createObjectURL(blob);

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Cidere2023.pdf");
          document.body.appendChild(link);

          link.click();

          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error("Error al descargar el archivo:", error);
        });
    },
    handleClickDialog(gWebPageReds) {
      this.dialogs.webPage = gWebPageReds;
      this.dialogs.page = true;
    },
    handleOutDialog() {
      this.dialogs.webPageRed = {};
      this.dialogs.page = false;
    },
    getImageGallery() {
      GImageGalleryService.query()
        .paginate()
        .then((res) => {
          this.gImageGallery = res;
        });
    },
    /**
     * Método que trae todas los concursos inactivos en base al filter, para mostrar en el carrusel
     */
    getGWebPageRed() {
      GListWebPageRedService.query()
        .paginate()
        .then((res) => {
          res.data = res.data.filter((gwebr) => gwebr.active == false);
          this.gWebPageReds = res;
          this.getGWebPageRed.data = res.data.map((item) => {
            item.size = "contain";
          });
        });
    },
    /**
     * Método que trae todas los concursos activos en base al filter, para mostrar en el carrusel
     */
    getGWebPageRedA() {
      GListWebPageRedService.query()
        .paginate()
        .then((res) => {
          res.data = res.data.filter((gwebr) => gwebr.active == true);
          this.gWebPageRedsA = res;
          this.getGWebPageRedA.data = res.data.map((item) => {
            item.size = "contain";
          });
        });
    },
  },
  mounted() {
    this.getImageGallery();
    this.getGWebPageRed();
    this.getGWebPageRedA();
  },
  props: {
    /**
     *
     */
    permalinks: {
      type: Object,
      default: () => ({
        noticias: "red",
      }),
    },
  },
};
