//
import GImageGalleryService from '@/services/api/General/GImageGallery'
import GSummaryService from '@/services/api/General/GSummary'
import PProviderService from '@/services/api/Provider/PProvider'
//
export default {
  name: 'v-view-home',
  data() {
    return {
      isMobile:false,
      windowHeight: window.innerWidth,
      mobilePropsTitle: {
        fontSize: '22px !important'
      },
      nomobilePropsTitle: {
        fontSize: '40px !important', 
      },
      mobilePropsP: {
        fontSize: '22px !important'
      },
      noMobilePropsP: {
        fontSize: '22px !important'
      },
      pProvidersSearch: {
        data: [],
        length: 0,
      },
      search: '',
      selected: {
        providers: []
      },
      busy: {
        summary: true,
      },
      model: 0,
      carousels: {
        match: 0,
        search: 0,
        filters: 0,
        profile: 0,
        library: 0,
      },
      colors: [
        'primary',
        'secondary',
        'yellow darken-2',
        'red',
        'orange',
      ],
      dialogs: {
        match: false,
        search: false,
        filters: false,
        profile: false,
        library: false,
      },
      gImageGallery: {
        data: [],
      },
      gSummary: {
        totalMatch: 0,
        totalMining: 0,
        totalProvider: 0,
      },
      filters: {
        descending: true,
        categoryId: null,
        categoryName: null,
        subcategoryId: null,
        subcategoryName: null,
        communeId: null,
        communeName: null,
        provinceId: null,
        provinceName: null,
        regionId: null,
        regionName: null,
        scoreId: null,
        search: "",
        serviceId: null,
        providerId: null,
        sizeId: null,
        sizeName: null,
        limit: 15,
        page: 1,
      },
      gSummaryPID: null,
      news: [
        {
          text: 'Conoce que es lo que hacemos',
          textProps: {
            class: 'white--text py-4 primary',
          },
          fileUrl: 'https://live.staticflickr.com/7362/11532233336_ca848d5fcf_h.jpg',
          color: 'primary',
        },
        {
          text: 'Instituciones que nos respaldan',
          textProps: {
            class: 'white--text py-2 primary',
            style: {
              marginBottom: '256px',
            },
          },
          fileUrl: '/images/logos_merge.png',
          color: 'white',
          size: 'contain',
        },
        // {
        //   text: 'Conoce la demanda regional',
        //   fileUrl: 'https://live.staticflickr.com/8695/16901544546_990e188743_h.jpg',
        //   color: 'primary',
        // },
        // {
        //   text: 'Acerca del espacio industrial minero',
        //   fileUrl: 'https://live.staticflickr.com/390/31845921436_2450d2a5b2_h.jpg',
        //   color: 'accent',
        // },
      ],
    }
  },
  filters: {

    /**
     * 
     */
    sheet: (gImageGallery) => {
      return {
        //marginTop: '126px',
        backgroundImage: `url(${ gImageGallery.fileUrl })`,
        backgroundPosition: `center center`,
        backgroundSize: `${ gImageGallery.size || 'cover' }`,
      }
    },
    sheetC: (gImageGallery) => {
      return {
        marginTop: '0px',
        backgroundImage: `url(${ gImageGallery.fileUrl })`,
        backgroundPosition: `center center`,
        backgroundSize: `${ gImageGallery.size || 'cover' }`,
      }
  }
  },
  created() {
    this.setLocalStorage();
    // window.addEventListener("resize", this.myEventHandler);
    let w = window.innerWidth;
    if (w < 768) {
      // console.log("viene chiquita")
      this.isMobile = true;
  } else if (w < 960) {
    // console.log("No tanto")
  }else{
    // console.log("No tanto")
    this.isMobile = false;
  }

  },
  computed: {
    isActive(){
      if(this.$vuetify.breakpoint.mdAndDown) return true
      return false
    },
    isMobileTitle(){
      if(this.$vuetify.breakpoint.mdAndDown){
        return this.mobilePropsTitle
      }else{
        return this.nomobilePropsTitle
      }
    },
    isMobileP(){
      if(this.$vuetify.breakpoint.mdAndDown){
        return this.mobilePropsP
      }else{
        return this.noMobilePropsP
      }
    },
  },
  methods: {

    setLocalStorage() {
      this.filters = JSON.parse(localStorage.getItem("filters") || "{}");
      if(this.filters.search != null){
        this.search = this.filters.search
      }
    },

    searchProviders(){
      let searchString = [...this.selected.providers]
      this.$router.push({
        name:"search",
        params:{ search: searchString, searchP:this.searchP},
      });
    },

    buscarPS() {
      this.filters.search = this.search;
      this.updateLSFilters(this.filters)
      this.$router.push({
        name:"search",
      });
    },

    clearPS() {
      this.filters.search = null;
      this.updateLSFilters(this.filters)
    },

    updateLSFilters(filters) {
      localStorage.setItem("filters", JSON.stringify(filters));
    },

    getProvidersSearch() {
      let search = String(this.searchP|| '')
      // console.log('getServices: ', search)

      if (search) {
        PProviderService.getAllBySearch(search).then(res => {
          // console.log({ res })
          res.data.forEach(item => {
            // console.log('compare', item, search)
            if (search.includes(item.name.toString().toLowerCase())) {
              // console.log('found', item)
              this.selected.providers = [
                ...this.selected.providers,
                item.id,
              ]
            }
          })
          this.pProvidersSearch = res
        })
      }
    },

    /**
     * 
     */
    cleanSummary() {
      clearInterval(this.gSummaryPID)
    },

    /**
     * 
     */
    getImageGallery() {
      GImageGalleryService.query().paginate().then(res => {
      // GImageGalleryService.getTest().then(res => {
        this.gImageGallery = res
        this.getImageGallery.data = res.data.map(item => {
          item.size = 'contain'
          //se cambia a vacio para efectos de la redireccion
          // ya que el backend solo lo devuelve en None
          if(item.hipervinculo=="None") item.hipervinculo=""
          if(item.hipervinculo.slice(0,4)!='http') item.hipervinculo='//'.concat(item.hipervinculo)
        })
      })
    },

    /**
     * 
     */
    async getSummary() {
      this.busy.summary = true
      await GSummaryService.query().fetch().then(res => {
        this.gSummary = res.data
      })
      this.busy.summary = false
    },

    /**
     * 
     */
    rememberSummary() {
      this.gSummaryPID = setInterval(this.getSummary, 15000)
    },
  },
  props: {

    /**
     * 
     */
    matchHelps: {
      type: Array,
      default: () => [
        {
          fileUrl: '/images/match_001.png',
          height: 400,
          text: 'Ingresa a un perfil de proveedor.',
          details: 'Los perfiles resumen la información del proveedor. Lo cual permite, a juicio de la compañía minera, realizar "MATCH" con la empresa.',
          size: 'contain',
        },
        {
          fileUrl: '/images/match_003.png',
          height: 400,
          text: 'Haz click en el botón "MATCH".',
          details: 'El cuadro derecho de los perfiles contiene la información de contacto de los proveedores.',
          size: 'contain',
        },
        {
          fileUrl: '/images/match_004.png',
          height: 400,
          text: 'Selecciona un contacto del proveedor para comunicarte.',
          details: 'Contacta directamente con las areas de administración, técnica y comercial de la empresa proveedora.',
          size: 'contain',
        },
      ]
    },

    /**
     * 
     */
    searchHelps: {
      type: Array,
      default: () => [
        {
          fileUrl: '/images/search_001.png',
          height: 400,
          text: 'Ingresa a la sección "Proveedores".',
          details: 'Escriba el servicio que necesita para obtener una lista de proveedores relacionados con su búsqueda.',
          size: 'contain',
        },
        {
          fileUrl: '/images/search_002.png',
          height: 400,
          text: 'Haga click en un proveedor para revisar su perfil.',
          details: 'Para revisar en detalle a un proveedor haz click en uno de los resultados de la búsqueda, y asi acceder a su perfil.',
          size: 'contain',
        },
      ]
    },

    /**
     * 
     */
    filtersHelps: {
      type: Array,
      default: () => [
        {
          fileUrl: '/images/filters_001.png',
          height: 400,
          text: 'Busque y seleccione el servicio del proveedor que busca.',
          details: 'Filtre los proveedores seleccionando los servicios, la categoría, tamaño de empresa, regiones, provincia y la comuna del proveedor que desea.',
          size: 'contain',
        },
        {
          fileUrl: '/images/filters_002.png',
          height: 400,
          details: 'Ordene los proveedores según su grado de experiencia, para ver en primera instancia a los mayor calificados.',
          text: 'Ordene los proveedores por su grado de experiencia.',
          size: 'contain',
        },
      ]
    },

    /**
     * 
     */
    profileHelps: {
      type: Array,
      default: () => [
        {
          fileUrl: '/images/profile_001.png',
          height: 400,
          text: 'Haz click en "Perfil" para modificar tus datos de proveedor.',
          details: 'Modifica tus datos de proveedor, (Logo, contactos, servicios, categorías entre otros) para entregar una mejor información.',
          size: 'contain',
        },
        {
          fileUrl: '/images/profile_002.png',
          height: 400,
          text: 'Agrega los contactos, proyectos, actividades y documentación de su empresa.',
          details: 'Puede agregar datos sobre sus proyectos, las areas de contacto, las actividades en las que se desempeña y documentación relacionada con su empresa.',
          size: 'contain',
        },
      ]
    },

    /**
     * 
     */
    libraryHelps: {
      type: Array,
      default: () => [
        {
          fileUrl: '/images/library_001.png',
          height: 400,
          text: 'Descargue los documentos facilitados por las compañías mineras.',
          details: 'Tendrá acceso a una biblioteca de recursos (documentos) otorgados por las compañías mineras de manera exclusiva a los proveedores inscritos en la plataforma.',
          size: 'contain',
        },
      ]
    },
  },
  watch:{
    'searchP'(){
      this.getProvidersSearch();
    },
    // 'window.innerWidth'(){
    //   // console.log("THE WINDOWS CHANGE", window.innerWidth)
    // },
    // windowHeight(newHeight,oldHeight){
    //   console.log(`it changed to ${newHeight} from ${oldHeight}`);
    // },
    // '$vuetify.breakpoint.xs'(){
    //   console.log(this.$vuetify.breakpoint, "el breakpoint")
    // }
  },
  mounted() {
    // let variable = this.$vuetify.breakpoint.xs
    // console.log(variable, "así quedó ")
    this.getImageGallery()
    this.getSummary()
    this.rememberSummary()
  },
  destroyed() {
    this.cleanSummary()
  },
}