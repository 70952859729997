//
import v8n from 'v8n'

/**
 * This class describes a Rules service.
 * 
 * @class RulesService (name)
 */
export default class RulesService {

  /**
   * 
   */
  static email = val => {
    return v8n().string().pattern(/[^\s@]+@[^\s@]+\.[^\s@]+/).test(val) || 'Formato incorrecto.'
  }

  /**
   * 
   */
  static length = length => val => {
    return v8n().length(length).test(val.toString()) || `${ length } caracteres.`
  }

  /**
   * 
   */
  static numeric = val => {
    return v8n().numeric().test(val) || `Solo números.`
  }

  /**
   * 
   */
  static max = limit => val => {
    return v8n().string().maxLength(limit).test(val) || `Máximo ${ limit } caracteres.`
  }

  /**
   * 
   */
  static min = limit => val => {
    return v8n().string().minLength(limit).test(val) || `Mínimo ${ limit } caracteres.`
  }

  /**
   * 
   */
  static required = val => {
    return v8n().not.null().test(val) || 'Campo requerido.'
  }

  /**
   * 
   */
  static notEmpty = val => {
    return v8n().string().minLength(1).test(val) || 'Campo requerido.'
  }

  /**
   * El método ruleRut() verifica el contenido de un string y confirma si cumple
   * la condición de modulo-11 para los valores validos para el registro único 
   * tributario chileno.
   * @param   {string}  value   El valor de entrada
   * @return  {boolean|string}  Retorna 'true' si es valido, y un mensaje si 
   *                            es invalido.
   */
  static rut = val => {

    // rut de administrador 
    // (no es un rut valido, es especifico para el administrador)
    if(val === '70298600-0') {
      return true
    }

    //
    var lower = val.toString().toLowerCase()
    var clean = lower.replace(/\./g,'').replace(/-/g, '')
    var body  = clean.slice(0, -1)
    var digit = clean.slice(-1)
  
    //
    var sum = 0
    var max = 7
    var mul = 2
  
    //
    for(let i = 1; i <= body.length; i++) {
      sum = sum + (mul * clean.charAt(body.length - i))
      mul = mul < max ? mul + 1 : 2
    }
  
    //
    var verify = 11 - (sum % 11)
  
    //
    return (verify == 10 ? 'k' : verify == 11 ? '0' : verify) == digit || 'Rut invalido.'
  }

  /**
   * 
   */
  static select = val => {
    return v8n().numeric().test(val) || `Selecciona una opción.`
  }

  /**
   * 
   */
  static url = val => {
    /* eslint-disable */
    if (!val) return true
    return v8n().string().pattern(/^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/).test(val) || 'URL incorrecta.'
  }

  static urlhttps = val => {
    /* eslint-disable */
    if (!val) return true
    return v8n().string().pattern(/^(http[s]?:\/\/){1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/).test(val) || 'URL incorrecta. Debe comenzar con http:// o https://'
  }
}