// required modules
import Service from '@/services/api/Service'
import GSummaryModel from '@/models/General/GSummary'

/**
 * This class describes a GSummary service.
 *
 * @class GSummary (name)
 */
export default class GSummaryService extends Service {

  /**
   * Define the GSummary model.
   *
   * @return {Function} GSummary resource.
   */
  static model() {
    return GSummaryModel
  }
}