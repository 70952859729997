// required modules
import Service from '@/services/api/Service'
import GImageGalleryModel from '@/models/General/GImageGallery'

/**
 * This class describes a GImageGallery service.
 *
 * @class GImageGallery (name)
 */
export default class GImageGalleryService extends Service {

  /**
   * Se agrega el hipervinculo para que las imagenes puedan tener
   * hipervinculos y hacer que redireccionen cuando se les da click en el carousel
   */
  static create(gImageGallery) {
    return gImageGallery.transfer(gImageGallery.form([ 'image','hipervinculo' ]))
  }

  /**
   * 
   */
  static delete(gImageGallery) {
    gImageGallery.id = gImageGallery.order
    return gImageGallery.delete()
  }

  static getTest() {
    return Promise.resolve({
      "current_page":1,
      "data":[
        {"order":1,"registeredAt":"2021-02-11 21:38:18","filename":"mineria_5.jpg","fileUrl":"/images/mineria_4.jpg"},
        {"order":1,"registeredAt":"2021-02-11 21:38:18","filename":"mineria_5.jpg","fileUrl":"/images/mineria_5.jpg"},
        {"order":1,"registeredAt":"2021-02-11 21:38:18","filename":"mineria_6.jpg","fileUrl":"/images/mineria_6.jpg"},
        {"order":1,"registeredAt":"2021-02-11 21:38:18","filename":"mineria_6.jpg","fileUrl":"/images/mineria_7.jpg"},
        {"order":1,"registeredAt":"2021-02-11 21:38:18","filename":"mineria_6.jpg","fileUrl":"/images/mineria_8.jpg"},
        // {"order":2,"registeredAt":"2021-02-11 21:38:18","filename":"banner2.jpg","fileUrl":"image_gallery\/banner2.jpg"},
        // {"order":3,"registeredAt":"2021-02-11 21:38:18","filename":"banner3.jpg","fileUrl":"image_gallery\/banner3.jpg"},
        // {"order":4,"registeredAt":"2021-02-11 21:38:18","filename":"banner4.jpg","fileUrl":"image_gallery\/banner4.jpg"},
        // {"order":5,"registeredAt":"2021-02-11 21:38:18","filename":"banner5.jpg","fileUrl":"image_gallery\/banner5.jpg"}
      ],
        // "first_page_url":"http:\/\/eindustrial.api.sys.dev.innlab.cl\/api\/image_gallery?page=1",
        "from":1,
        "last_page":1,
        "per_page":15,
        "prev_page_url":null,
        "to":5,
        "total":5
      })
  }

  /**
   * 
   */
  static getBy(opts = {}) {
    // return this.getTest(opts)
    return this
      .query()
      .page(opts.page)
      .limit(opts.limit)
      .paginate()
  }

  /**
   * Define the GImageGallery model.
   *
   * @return {Function} GImageGallery resource.
   */
  static model() {
    return GImageGalleryModel
  }

  /**
   * 
   */
  static swapDown(gImageGallery) {
    return this.shape({ id: 'swap' }).attach({
      orderSource: gImageGallery.order,
      orderDestination: gImageGallery.order + 1,
    })
  }

  /**
   * 
   */
  static swapUp(gImageGallery) {
    return this.shape({ id: 'swap' }).attach({
      orderSource: gImageGallery.order,
      orderDestination: gImageGallery.order - 1,
    })
  }

  /**
   * 
   * @param {*} gImageGallery 
   */
  static toCreate() {
    return this.shape({
      image: '',
    })
  }

  /**
   * Se agrega el hipervinculo para que las imagenes puedan tener
   * hipervinculos y hacer que redireccionen cuando se les da click en el carousel
   */
  static update(gImageGallery) {
    return this.shape({ id: gImageGallery.order }).transfer(gImageGallery.form(['image','hipervinculo']))
  }

  static updateHiperVinculo(gImageGallery) {
    return this.shape({ id: gImageGallery.order +'&only'} ).transfer(gImageGallery.form(['hipervinculo']))
  }

}