// model
import Model from "@/models/Model";

/**
 * This class describes a AthRegister model.
 *
 * @class AthRegisterModel (name)
 */
export default class AthRegisterModel extends Model {
  /**
   *
   */
  get has_equal_passwords() {
    return this.password === this.repeat_password;
  }
  /**
   *
   */
  get is_not_provider() {
    return !this.is_provider;
  }

  /**
   * AthRegister endpoint
   *
   * @returns {string} model endpoint
   */
  static resource() {
    return "register";
  }

  /**
   * Transform AthRegister to AthLogin
   *
   * @returns {AthLoginModel} AthLogin model
   */
  login() {
    return this.custom("login").many("");
  }

  /**
   * AthRegister/Natural endpoint
   *
   * @returns {AthRegisterModel} AthRegister/Natural model
   */
  natural() {
    return this.many("natural");
  }

  /**
   * AthRegister/Provider endpoint
   *
   * @returns {AthRegisterModel} AthRegister/Provider model
   */
  provider() {
    return this.many("provider");
  }

  /**
   * AthRegister/Natural body
   *
   * @returns {AthRegisterModel} AthRegister/Natural body
   */
  toLogin() {
    return this.reduce(["rut", "password"]);
  }

  /**
   * AthRegister/Natural body
   *
   * @returns {AthRegisterModel} AthRegister/Natural body
   */
  toNatural() {
    return this.reduce([
      "g-recaptcha-response",
      "address",
      "rut",
      "name",
      "email",
      "phone",
      "password",
    ]);
  }

  /**
   * AthRegister/Provider body
   *
   * @returns {AthRegisterModel} AthRegister/Provider body
   */
  toProvider() {
    return this.reduce([
      "g-recaptcha-response",
      "address",
      "businessName",
      "categoryId",
      "communeId",
      "description",
      "descriptionExp",
      "email",
      "latitude",
      "longitude",
      "name",
      "password",
      "rut",
      "sizeId",
      "webUrl",
      "addressBranch",
    ]);
  }
}
