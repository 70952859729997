/**
 * 
 */
export default class Lang extends Map {

  /**
   * 
   */
  static shape() {
    return new this()
  }
}