import { render, staticRenderFns } from "./red.template.pug?vue&type=template&id=3482b2be&lang=pug&external"
import script from "./red.script.js?vue&type=script&lang=js&external"
export * from "./red.script.js?vue&type=script&lang=js&external"
import style0 from "./red.style.css?vue&type=style&index=0&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports