//
const CATCHER_SERVICE_JOIN_CHAR = '\n'
const CATCHER_SERVICE_DEFAULT_MAP = new Map()

//
export default class CatcherService {

  /**
   * 
   */
  static translate(payload, translates = CATCHER_SERVICE_DEFAULT_MAP) {
    return Promise
      .resolve(payload)
      .then(this.getError)
      .then(this.getMessage(translates))
  }

  /**
   * 
   */
  static getError = (payload) => {

    // verify nested data
    if (!payload.response)      return payload
    if (!payload.response.data) return payload.response

    // return default nested data
    return payload.response.data
  }

  /**
   * 
   */
  static getMessage = (translates) => (payload) => {

    // verify nested data
    if (payload.errors)   return this.mapErrors(payload.errors, translates)
    if (payload.message)  return payload.message
    if (payload.error)    return payload.error

    // return default response
    return payload
  }

  /**
   * 
   */
  static mapError = (translates) => (payload) => {

    //
    const message   = payload.join(CATCHER_SERVICE_JOIN_CHAR)
    const translate = translates.get(message)
    const response  = translate || message

    //
    return response
  }

  /**
   * 
   */
  static mapErrors = (payload, translates) => {
    return Object
      .values(payload)
      .map(this.mapError(translates))
      .join(CATCHER_SERVICE_JOIN_CHAR)
  }
}